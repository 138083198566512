export const authDomain = process.env.NEXT_PUBLIC_AUTH_DOMAIN || '';
export const authClientId = process.env.NEXT_PUBLIC_AUTH_CLIENTID || '';
export const authAudience = process.env.NEXT_PUBLIC_AUTH_AUDIENCE || '';
export const authNamespace = process.env.NEXT_PUBLIC_AUTH_NAMESPACE || '';

export const baseUrl = process.env.NEXT_PUBLIC_DOMAIN || '';
export const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '';

export const landingPageUrl = process.env.NEXT_PUBLIC_LANDING_PAGE_URL;

export const sombraApi = process.env.NEXT_PUBLIC_SOMBRA_API;
export const graphqlApi = process.env.NEXT_PUBLIC_GRAPHQL_API;
export const logbookApi = process.env.NEXT_PUBLIC_LOGBOOK_API;
export const logbookPortal = process.env.NEXT_PUBLIC_LOGBOOK_PORTAL;

export const mapBoxKey = process.env.NEXT_PUBLIC_MAPBOX_TOKEN || '';

export const mixpanelEnabled =
  process.env.NEXT_PUBLIC_MIXPANEL_ENABLED === 'true';
export const mixpanelKey = process.env.NEXT_PUBLIC_MIXPANEL_KEY || '';

export const intercomEnabled =
  process.env.NEXT_PUBLIC_INTERCOM_ENABLED === 'true';
export const intercomKey = process.env.NEXT_PUBLIC_INTERCOM_KEY || '';

export const pdftronLicenseKey = process.env.NEXT_PUBLIC_PDFTRON_LICENSE_KEY;
export const pdftronServer =
  process.env.NEXT_PUBLIC_PDFTRON_WEBVIEWER_SERVER || '';
export const pdftronVersion = '8.5.0';

export const deploymentType = process.env.NEXT_PUBLIC_DEPLOYMENT_TYPE;

export const ciCommitHash = process.env.NEXT_PUBLIC_CI_COMMIT_SHA
  ? process.env.NEXT_PUBLIC_CI_COMMIT_SHA.substring(0, 5)
  : 'unknown';

export const flatfilePK = process.env.NEXT_PUBLIC_FLATFILE_PK;
export const flatfileEnvId = process.env.NEXT_PUBLIC_FLATFILE_ENV_ID;

export const lookerDashboards = JSON.parse(
  process.env.NEXT_PUBLIC_LOOKER_DASHBOARDS || '[]',
) as LookerDashboard[];

type LookerDashboard = {
  lookmlId: string;
  uddId: string;
};

export const getStreamAppId = process.env.NEXT_PUBLIC_GET_STREAM_APP_ID || '';
export const getStreamApiKey = process.env.NEXT_PUBLIC_GET_STREAM_API_KEY || '';

export const sentryEnabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';
export const sentryDSN = process.env.NEXT_PUBLIC_SENTRY_DSN || '';

export const zapierClientId = process.env.NEXT_PUBLIC_ZAPIER_CLIENT_ID;

export const helpCenterLinkFR = process.env.NEXT_PUBLIC_HELP_CENTER_LINK_FR;
export const helpCenterLinkEN = process.env.NEXT_PUBLIC_HELP_CENTER_LINK_EN;

export const novuAppId = process.env.NEXT_PUBLIC_NOVU_APP_ID || '';
export const novuBackendUrl = process.env.NEXT_PUBLIC_NOVU_BACKEND_URL || '';
export const novuWebsocketUrl =
  process.env.NEXT_PUBLIC_NOVU_WEBSOCKET_URL || '';

export const bugReportLinkFr = process.env.NEXT_PUBLIC_BUG_REPORT_LINK_FR;
export const bugReportLinkEn = process.env.NEXT_PUBLIC_BUG_REPORT_LINK_EN;

export const featureRequestLinkFr =
  process.env.NEXT_PUBLIC_FEATURE_REQUEST_LINK_FR;
export const featureRequestLinkEn =
  process.env.NEXT_PUBLIC_FEATURE_REQUEST_LINK_EN;
export const appcuesEnabled =
  process.env.NEXT_PUBLIC_APPCUES_ENABLED === 'true';
export const appcuesAccountId = process.env.NEXT_PUBLIC_APPCUES_ACCOUNT_ID;

export const posthogEnabled =
  process.env.NEXT_PUBLIC_POSTHOG_ENABLED === 'true';
export const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;
export const cloudflareTurnstileSiteKey =
  process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY || '';
